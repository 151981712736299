/* ContentfulComponent.css */

body {
  background-color: #121212; /* Dark background for the page */
  color: #ffffff;
  font-family: 'Roboto', sans-serif; /* Using Roboto for modern look, make sure to include this font in your HTML or import it from Google Fonts */
}


.tabs button {
  padding: 10px;
  margin-right: 5px;
  border: none;
  background-color: #eee;
  cursor: pointer;
}

.tabs button.active {
  background-color: #ccc;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; 
  margin-top: 40px; 
}

:root {
--primary-color: white;
--secondary-color: rgb(61, 68, 73);
--highlight-color: #3282b8;

--dt-status-available-color: greenyellow;
--dt-status-away-color: lightsalmon;
--dt-status-offline-color: lightgray;

--dt-padding: 12px;
--dt-padding-s: 6px;
--dt-padding-xs: 2px;

--dt-border-radius: 3px;

--dt-background-color-container: #2a3338;
--dt-border-color: var(--secondary-color);
--dt-bg-color: var(--highlight-color);
--dt-text-color: var(--primary-color);
--dt-bg-active-button: var(--highlight-color);
--dt-text-color-button: var(--primary-color);
--dt-text-color-active-button: var(--primary-color);
--dt-hover-cell-color: var(--highlight-color);
--dt-even-row-color: var(--secondary-color);
--dt-focus-color: var(--highlight-color);
--dt-input-background-color: var(--secondary-color);
--dt-input-color: var(--primary-color);
}

.material-icons {
font-size: 16px;
}

/* Container for the table */
.datatable-container {
  background-color: #1e1e1e; /* Dark shade for the table background */
  border-radius: 8px; /* Rounded corners for the table container */
  overflow: hidden; /* Ensures the border radius clips the inner table */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  margin: 20px auto; /* Center the table and add some margin */
  padding: 1rem;
  border: 1px solid #333; /* Adding a border to the container for more definition */
  overflow-x: auto;
}

.datatable-container .header-tools {
border-bottom: solid 1px var(--dt-border-color);
padding: var(--dt-padding);
padding-left: 0;
display: flex;
align-items: baseline;
}

.datatable-container .datatable th:first-child,
.datatable-container .datatable td:first-child {
  border-right: 2px solid #333; /* Adds a solid line to separate the Rank column */
}

.datatable-container .header-tools .search {
width: 30%;
}

.datatable-container .header-tools .search .search-input {
width: 100%;
height: calc(1.5em + 0.75rem + 2px);
padding: 0.375rem 0.75rem;
background-color: var(--dt-input-background-color);
display: block;
box-sizing: border-box;
border-radius: var(--dt-border-radius);
border: solid 1px var(--dt-border-color);
color: var(--dt-input-color);
}

.datatable-container .header-tools .tools {
width: 70%;
}

.datatable-container .header-tools .tools ul {
margin: 0;
padding: 0;
display: flex;
justify-content: start;
align-items: baseline;
}

.datatable-container .header-tools .tools ul li {
display: inline-block;
margin: 0 var(--dt-padding-xs);
align-items: baseline;
}

.datatable-container .footer-tools {
padding: var(--dt-padding);
display: flex;
align-items: baseline;
}

.datatable-container .footer-tools .list-items {
width: 50%;
}

.datatable-container .footer-tools .pages {
margin-left: auto;
margin-right: 0;
width: 50%;
}

.datatable-container .footer-tools .pages ul {
margin: 0;
padding: 0;
display: flex;
align-items: baseline;
justify-content: flex-end;
}

.datatable-container .footer-tools .pages ul li {
display: inline-block;
margin: 0 var(--dt-padding-xs);
}

.datatable-container .footer-tools .pages ul li button,
.datatable-container .header-tools .tools ul li button {
color: var(--dt-text-color-button);
width: 100%;
box-sizing: border-box;
border: 0;
border-radius: var(--dt-border-radius);
background: transparent;
cursor: pointer;
}

.datatable-container .footer-tools .pages ul li button:hover,
.datatable-container .header-tools .tools ul li button:hover {
background: var(--dt-bg-active-button);
color: var(--dt-text-color-active-button);
}

.datatable-container .footer-tools .pages ul li span.active {
background-color: var(--dt-bg-color);
border-radius: var(--dt-border-radius);
}

.datatable-container .footer-tools .pages ul li button,
.datatable-container .footer-tools .pages ul li span,
.datatable-container .header-tools .tools ul li button {
padding: var(--dt-padding-s) var(--dt-padding);
}

.datatable-container .datatable {
border-collapse: collapse;
width: 100%; /* Adjusted width to 100% */
}

.datatable-container .datatable th {
font-weight: bolder;
text-align: center; /* Centered the column titles */
border-bottom: solid 1px var(--dt-border-color);
}

.datatable-container .datatable td {
border-bottom: solid 1px var(--dt-border-color);
padding: var(--dt-padding) var(--dt-padding); /* Added padding to maintain row height */
text-align: center; /* Centered the data */
}

.datatable-container .datatable tbody tr:hover {
background-color: var(--dt-hover-cell-color);
}

.datatable-container .datatable tbody tr .available::after,
.datatable-container .datatable tbody tr .away::after,
.datatable-container .datatable tbody tr .offline::after {
display: inline-block;
vertical-align: middle;
}

.datatable-container .datatable tbody tr .available::after {
content: "Online";
color: var(--dt-status-available-color);
}

.datatable-container .datatable tbody tr .away::after {
content: "Away";
color: var(--dt-status-away-color);
}

.datatable-container .datatable tbody tr .offline::after {
content: "Offline";
color: var(--dt-status-offline-color);
}

.datatable-container .datatable tbody tr .available::before,
.datatable-container .datatable tbody tr .away::before,
.datatable-container .datatable tbody tr .offline::before {
content: "";
display: inline-block;
width: 10px;
height: 10px;
margin-right: 10px;
border-radius: 50%;
vertical-align: middle;
}

.datatable-container .datatable tbody tr .available::before {
background-color: var(--dt-status-available-color);
}

.datatable-container .datatable tbody tr .away::before {
background-color: var(--dt-status-away-color);
}

.datatable-container .datatable tbody tr .offline::before {
background-color: var(--dt-status-offline-color);
}


.transparent-image {
  opacity: 0.01; 
}

.container {
  position: relative;
}

.image-container {
  opacity: 0.05; /* Adjust the opacity as desired */
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  position: absolute;
  left: 10px; /* Adjust as needed */
  margin-top: -40px;
  font-family: copperplate; /* Replace 'Your Cool Font' with the name of your desired font */
  font-size: 36px; /* Adjust font size as needed */
  color: #fff; /* Adjust text color as needed */
}

@media only screen and (max-width: 768px) {
  .datatable-container .datatable th,
  .datatable-container .datatable td {
      padding: 3.3px; /* Ensure there's no padding */
      text-align: center; /* Maintain center alignment */
  }

  /* Optionally, set specific widths for certain columns */
  .datatable-container .datatable th:nth-child(1), /* Example: Rank column */
  .datatable-container .datatable td:nth-child(1) {
      width: 10%; /* Adjust according to your content and needs */
  }

  /* Increase font size more suitably now that padding is reduced */
  .datatable-container {
      font-size: 13px; /* Adjusted font size */
  }

  /* Reducing button size to fit better */
  .tabs button {
      padding: 4px 6px; /* Adjust padding */
      font-size: 14px; /* Adjust font size */
  }

  /* Hide less important columns */
  .datatable-container .datatable th.mobile-hide,
  .datatable-container .datatable td.mobile-hide {
      display: none;
  }
}

.last-updated {
  position: absolute;
  top: 0px; 
  right: 10px;
  margin-top: -30px;
  font-style: italic;
  z-index: 1000; 
  font-size: 14px;
}
@media (max-width: 600px) {
  .last-updated {
    font-size: 10px;
  }
}